import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Action Text
require("trix")
require("@rails/actiontext")

$(".best_in_place").best_in_place();

require('galleria')

import Galleria from 'galleria';

$(document).on('turbolinks:load', function() {
  function galleriaInitialization () {
    Galleria.loadTheme('https://cdnjs.cloudflare.com/ajax/libs/galleria/1.6.1/themes/classic/galleria.classic.min.js');
    $('.galleria-img').click(function(){
      var img_index = $(this).data('index');

      // initialize galleria 
      Galleria.run('.galleria', {
        width: $( window ).width(),
        height: ($( window ).height() * 0.98),
        show: true,
        clicknext: true,
        extend: function() {
          // gallery is instanse object of Galleria
          var gallery = this; 
          gallery.show(img_index);
          $('.gallery-show-title').hide();
          $(window).scrollTop(0);
          // customize galleria view and add close button with trigger
          $('.galleria.container-fluid').prepend("<span class='close close-galleria' style='position: fixed; top: 75px; right: 25px; height: 0px; z-index: 10; color: white;' aria-hidden='true'>&times;</span>");
          
          $(document).keyup(function(e) {
            if (e.key === "Escape") {
              $('.gallery-show-title').show();
              gallery.destroy();
              galleriaInitialization();
            } else if (e.key === "ArrowRight") {
              gallery.next();
            } else if (e.key === "ArrowLeft") {
              gallery.prev();
            }
          });
          
          $('.close.close-galleria').click(function(){
            $('.gallery-show-title').show();
            gallery.destroy();
            galleriaInitialization();
          });
      }});
    });
  };
  $(document).ready(function(){
    galleriaInitialization();
  });
  window.jQuery = $;
  window.Galleria = Galleria
});
  